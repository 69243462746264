<script setup></script>

<template>
  <div class="top-lang">
    <LanguageSelect />
  </div>

  <notifications width="600px" position="top center">
    <template #body="props">
      <div class="pinotqr-notification" :class="props.item.type">
        <div class="icon">
          <font-awesome
            v-if="props.item.type === 'success'"
            icon="check"
            class="pinotqr-notification-icon"
          />
          <font-awesome v-else icon="xmark" class="pinotqr-notification-icon" />
        </div>
        <div v-html="props.item.text" />
      </div>
    </template>
  </notifications>
  <div class="content">
    <div class="left-panel hidden md:flex">
      <svg
        class="mb-24"
        width="30px"
        height="50px"
        viewBox="0 0 30 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="10" width="3.75" height="30" fill="white" />
        <rect y="36.25" width="30" height="3.75" fill="white" />
        <rect x="7.5" y="10" width="3.75" height="22.5" fill="white" />
        <rect x="15" y="10" width="3.75" height="15" fill="white" />
        <rect x="22.5" y="10" width="3.75" height="7.5" fill="white" />
        <rect x="22.5" y="13.75" width="7.5" height="3.75" fill="white" />
        <rect x="7.5" y="28.75" width="22.5" height="3.75" fill="white" />
        <rect x="15" y="21.25" width="15" height="3.75" fill="white" />
      </svg>
      <div class="title">{{ $t("login.welcome") }}</div>
      <div class="mt-3">
        {{ $t("login.description") }}
      </div>
      <div class="mt-6">
        <font-awesome icon="check" class="mr-3" />
        {{ $t("login.free") }}
      </div>
      <div class="mt-2">
        <font-awesome icon="check" class="mr-3" />
        {{ $t("login.no-credit-card") }}
      </div>
      <div class="mt-2">
        <font-awesome icon="check" class="mr-3" />
        {{ $t("login.cancel-anytime") }}
      </div>
    </div>
    <div class="m-auto mt-48 md:mt-auto w-full">
      <NoAuthGuard>
        <router-view></router-view>
      </NoAuthGuard>
    </div>
  </div>

  <ClientOnly>
    <cookie locale="en" />
  </ClientOnly>
</template>

<style scoped>
.right-panel {
  flex: 1;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 100%;

  .left-panel {
    color: #fff;
    width: 400px;
    height: 100vh;
    padding: 60px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;

    .title {
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px; /* 133.333% */
    }

    background: #4a3aff;
  }
}

.top-lang {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
